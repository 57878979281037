import OnlineSolutions from "@/components/OnlineSolutions.vue";
import StoreDetails from "@/components/StoreDetails.vue";

import {createRouter, createWebHistory} from "vue-router";
import OmniBox from "@/components/OmniBox.vue";

const routes = [
    {meta: {depth: 1, sidebar:{init:'min'}, back:false}, name: 'home', path: '/', components: {sidebar: OmniBox}},
    {meta: {depth: 3, sidebar:{init:'max'}, back:'home'}, name: 'online-solutions', path: '/online-solutions', components: {sidebar: OnlineSolutions}},
    {meta: {depth: 4, sidebar:{init:'mid'}, back:-1}, name: 'store', path: '/store/:id', components: {sidebar: StoreDetails}}
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.body.classList.remove('route-' + from.name)
    document.body.classList.add('route-' + to.name)
    next()
})

export default router