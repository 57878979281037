import { createApp } from 'vue'
import { createI18n } from "vue-i18n";
import axios from 'axios'
import VueAxios from 'vue-axios'
import mitt from 'mitt';
import { Vue3SlideUpDown } from "vue3-slide-up-down";

import fr from "../locales/fr.json";

import App from './App.vue'
import store from './scripts/store'
import {drag, clickOutside, track, trackButton} from './scripts/directives'

const emitter = mitt();

const i18n = createI18n({
    locale: "fr",
    fallbackLocale: "fr",
    globalInjection: true,
    legacy: false,
    messages: { fr }
});

import router from "@/scripts/router";

const app = createApp(App)

app.directive('drag', drag);
app.directive('clickOutside', clickOutside);
app.directive('track', track);
app.directive('trackButton', trackButton);

app.component('slideUpDown', Vue3SlideUpDown);

app.use(i18n)
app.use(store)
app.use(router)
app.use(VueAxios, axios)

app.config.globalProperties.emitter = emitter;

app.mount('#root')