<template>
  <section class="c-location">
    <div class="c-location__content">
      <h2 class="c-location__title">{{ $t('home.title') }}</h2>
      <div class="c-location__search" :class="{'has-results':addresses.length,'is-loading':loading}">
        <form @submit.prevent="submit">
          <input class="c-location__query" type="search" :placeholder="$t('search.address')" v-model="query"/>
        </form>
        <ul v-if="addresses.length" class="c-location__results">
          <li v-for="address in addresses" :key="address.id">
            <a @click="select(address)">{{address.label}}</a>
          </li>
        </ul>
      </div>
      <button class="c-location__init" @click="detectPosition" v-track-button="{location:'popup'}">{{ $t('filters.gps') }}</button>
    </div>
  </section>
</template>

<script>

import {autocomplete} from "@/scripts/api";

export default {
  data(){
    return {
      addresses: [],
      hold: false,
      query: '',
      loading: false,
      autocomplete_timeout:false
    }
  },
  watch:{
    query(){

      if( this.query.length > 3 ){

        clearTimeout(this.autocomplete_timeout);
        this.autocomplete_timeout = setTimeout(()=>this.search(this.query), 300)
      }
    }
  },
  methods:{
    submit(){
      if( this.loading )
        this.hold = true
      else if( this.addresses.length )
        this.select(this.addresses[0])
    },
    detectPosition(){
      this.emitter.emit("detectPosition");
    },
    blur(){
      this.addresses = []
    },
    select(address){

      this.$store.commit('position', address.latLng);

      if( address.type === 'municipality' )
        this.emitter.emit('goTo', {latLng:address.latLng, zoom:13})
      else
        this.emitter.emit('goTo', {latLng:address.latLng, zoom:14})
    },
    async search(query) {

      this.loading = true
      this.addresses = await autocomplete(query, 5)
      this.loading = false

      if( this.hold ){
        this.hold = true
        this.select(this.addresses[0])
      }
    }
  }
}
</script>

<style lang="scss">
.c-location{
  display: flex; flex-flow: column; height: 100%; align-items: center; justify-content: center; backdrop-filter: blur(4px);
  position: overlay(); z-index: 99999;
  &__content{
    background: $c-background; border-radius: $radius; padding: $space-m; max-width: 33rem; box-shadow: $box-shadow
  }
  &__title{
    font-weight: 600; font-size: $font-m; text-align: center; padding: 0 $space-l; display: flex; flex-flow: column;
    align-items: center; margin-bottom: $space-m;
    &:before{
      content: ''; width: 3rem; height: 3rem; background: url("../assets/picto/pin.svg") no-repeat center;
      background-size: contain; display: inline-block; margin-bottom: $space
    }
  }
  &__init{
    border-radius: $radius; border: 0; background: url("../assets/picto/gps-white.svg") no-repeat 2rem 50% $c-black;
    background-size: auto 2rem; height: 5rem; width: 100%; padding-left: 6rem; color: $c-white; text-align: left;
    margin-top: $space-s; cursor: pointer;
  }
  &__search{
    position: relative;
    &.has-results input{ border-radius: $radius $radius 0 0 }
    &.is-loading input{ background-image: url("../assets/picto/loader.svg") }
  }
  &__query{
    border-radius: $radius; border: 0; background: url("../assets/picto/magnifier.svg") no-repeat 2rem 50% $c-white;
    background-size: auto 2rem; height: 5rem; width: 100%; padding-left: 6rem;
  }
  &__results{
    position: absolute; top: 4rem; left: 0; width: 100%; background: $c-white; padding: $space; padding-top: 0;
    border-radius: 0 0 $radius $radius; max-height: min(30vh, 23rem); overflow: auto;
    @include scrollbar(4px, rgba(0,0,0,0.05), rgba(0,0,0,0), 4px, 4px);
    li{
      a{
        font-size: $font-xs; cursor: pointer; transition: allow(color); display: block; padding: $space-xs 0;
        &:hover{ color: $c-theme }
      }
      &+li{ border-top: 1px solid $c-border }
    }
  }
}
</style>
